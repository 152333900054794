// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "S_jx";
export var marketplaceAppDevCaseSection = "S_jF";
export var marketplaceAppDevClientQuotes = "S_jH";
export var marketplaceAppDevIndustriesSection = "S_jw";
export var marketplaceAppDevOnlineSection = "S_jB";
export var marketplaceAppDevPracticesSection = "S_jC";
export var marketplaceAppDevPrimeSection = "S_jt";
export var marketplaceAppDevProcessSection = "S_jy";
export var marketplaceAppDevProjLogoSection = "S_jD";
export var marketplaceAppDevServicesSection = "S_jv";
export var marketplaceAppDevTechSection = "S_jz";
export var marketplaceAppExpertiseSection = "S_jG";